<template>
  <div>
    <h1 class="h3">
      {{ $t('product.listProduct') }}
    </h1>
    <div class="row mt-4">
      <div class="col-md-5">
        <a-input-search
          v-model="params.q"
          size="large"
          :placeholder="$t('store_list_page.search_products')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="onSearch"
        >
          <a-button slot="enterButton" type="danger" icon="search">
            {{ $t('utils.search') }}
          </a-button>
        </a-input-search>
      </div>
      <div class="col-md-3 p-0">
        <div class="d-flex align-items-center">
          <div class="flexno pr-2">
            {{ `${$t('order.sortBy')}:` }}
          </div>
          <a-select
            :default-value="sorting"
            size="large"
            class="select-antd-default ml-2"
            style="width: 220px"
            placeholder="Urutkan Produk"
            :options="options"
            :disabled="isLoading"
            @change="onSorting"
          />
        </div>
      </div>
      <div class="col-md-4 text-right">
        <a-button
          v-if="businessId && permission.includes('WRITE')"
          type="primary"
          size="large"
          class="mr-3"
          ghost
          :disabled="isLoading"
          @click="toPage('import')"
        >
          Impor
        </a-button>
        <a-button
          v-if="businessId && permission.includes('WRITE')"
          type="primary"
          size="large"
          :disabled="isLoading"
          @click.prevent="toPage('create')"
        >
          {{ $t('product.addProduct') }}
        </a-button>
      </div>
    </div>
    <div class="d-flex justify-start align-items-center mt-4" style="gap: 10px">
      <a-button type="primary" :loading="isLoading" @click.prevent="exportDataTable">
        {{ $t('warehouse.export_data') }}
      </a-button>
    </div>
    <div class="mt-3">
      <a-table
        class="master-list"
        :data-source="products"
        :row-key="(record) => record.id"
        :columns="columns"
        :expand-icon-as-cell="false"
        :expand-icon-column-index="0"
        :loading="isLoading"
        :pagination="false"
        :scroll="scroll"
      >
        <div slot="expandIcon" slot-scope="props" class="d-flex align-items-center">
          <div style="cursor: pointer" @click="toPage('edit', props.record.id)">
            <PcaImage
              width="54"
              height="54"
              :src="props.record.showimg_url || $defaultImage"
              :alt="props.record.title"
              class="product-image mr-3 flexno of-contain"
            />
          </div>
          <div>
            <p class="master-brand">
              {{ getBrandName(props.record) }}
            </p>
            <a-tooltip v-if="props.record.title && props.record.title.length > 41" placement="top">
              <template slot="title">
                <span>{{ props.record.title }}</span>
              </template>
              <p class="master-title" style="cursor: pointer" @click="toPage('edit', props.record.id)">
                {{ `${props.record.title.substring(0, 41)}...` }}
              </p>
            </a-tooltip>
            <p v-else class="master-title" style="cursor: pointer" @click="toPage('edit', props.record.id)">
              {{ props.record.title || '-' }}
            </p>
            <p class="master-sku">
              <span v-if="+props.record.variant_count > 1" @click="e => onOpen(props, e)">
                {{ $t(`product.${props.record.isOpen ? 'close_variant' : 'view_variant'}`) }}
              </span>
              <template v-else>
                SKU: {{ props.record.catalogs?.[0]?.sku || '-' }}
              </template>
            </p>
          </div>
        </div>
        <template slot="category" slot-scope="text">
          {{ text?.name || '-' }}
        </template>
        <template slot="status" slot-scope="text">
          <a-tag :color="colors[text]" class="w-100">
            {{ $t(`store_list_page.${text.toLowerCase()}`) }}
          </a-tag>
        </template>
        <template slot="action" slot-scope="record">
          <div class="text-right">
            <a-dropdown v-if="permission.includes('READ') || permission.includes('WRITE')">
              <a @click.prevent="">
                <a-icon type="more" />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item
                    v-if="permission.includes('READ')"
                    key="1"
                    class="py-2"
                    @click="toPage('edit', record.id)"
                  >
                    <a-icon type="eye" class="mr-2" />
                    Detail
                  </a-menu-item>
                  <a-menu-item
                    v-if="permission.includes('READ')"
                    key="2"
                    class="py-2"
                    @click="toPage('allocation', record.id)"
                  >
                    <a-icon type="container" class="mr-2" />
                    {{ $t('product.stock_allocation') }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
        <div v-if="text.catalogs.length > 1" slot="expandedRowRender" slot-scope="text">
          <table class="catalog-list">
            <thead>
              <tr style="border: 1px solid #e4e9f0">
                <th>{{ $t('product.picture') }}</th>
                <th>SKU</th>
                <th v-for="(option, index) in Object.keys(text.catalogs[0].options)" :key="index">
                  {{ option }}
                </th>
                <th>{{ $t('product.price') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="catalog in text.catalogs" :key="catalog.id" style="border: 1px solid #e4e9f0">
                <td>
                  <PcaImage
                    width="40"
                    height="40"
                    :src="catalog.image_url?.[0]?.imagePath || $defaultImage"
                    :alt="catalog.title"
                    class="product-image mr-3 flexno of-contain"
                  />
                </td>
                <td>
                  {{ catalog?.sku || '-' }}
                </td>
                <td v-for="(option, index) in Object.keys(text.catalogs[0].options)" :key="index">
                  {{ catalog.options[option] }}
                </td>
                <td>
                  {{ catalog?.price || '-' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </a-table>
    </div>
    <div class="mt-3 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="total_row"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { getPriceRange } from '@/api/price'
import { useCurrency } from '@/composable/useCurrency'
import { columnsMaster } from '@/data/columns'
import PcaImage from '@/components/Image'
import XLSX from 'xlsx';
import { getProductMasterList } from '@/api/product'
import { getInventoryListV2 } from '@/api/inventory'

const { format } = useCurrency()

export default {
  components: {
    PcaImage,
  },
  data() {
    return {
      screenHeight: ((screen.height - 900) * 0.5) + (screen.height * 0.5),
      columns: columnsMaster,
      isLoading: false,
      sorting: 'createdAt.desc',
      total_row: 0,
      params: {
        q: '',
        page: 1,
        limit: 10,
        sortBy: 'createdAt',
        sortDir: 'desc',
      },
      colors: {
        ACTIVE: 'blue',
        NOT_ACTIVE: 'red',
        INACTIVE: 'red',
      },
      products: [],
      sizeOptions: ['10', '20', '30', '40', '50'],
      options: [
        { value: 'createdAt.desc', label: this.$t('product.new_product') },
        { value: 'createdAt.asc', label: this.$t('product.old_product') },
        { value: 'title.asc', label: `${this.$t('product.product_name')} (A - Z)` },
        { value: 'title.desc', label: `${this.$t('product.product_name')} (Z - A)` },
      ],
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    scroll() {
      return { y: this.screenHeight > 450 ? this.screenHeight : 450, x: 1000 }
    },
    tHeight() {
      return !this.products.length
        ? '0px'
        : this.screenHeight > 450
          ? `${this.screenHeight}px`
          : '450px'
    },
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
  },
  watch: {
    '$store.state.products.products'(value) {
      this.fetchPrice(value)
    },
    '$route.query'() {
      this.init()
    },
    businessId() {
      this.init()
    },
    // permission(newValue) {
    //   if(!newValue.length) this.$router.push({ path: '/error/403', query: { ...this.$route.query } })
    // },
  },
  mounted() {
    this.init()
  },
  methods: {
    init: debounce(function () {
      this.fetchProducts()
    }, 500),
    async fetchProducts() {
      if (this.businessId) {
        this.isLoading = true
        this.total_row = 0
        await this.$store
          .dispatch('products/GETPRODUCTLISTMASTER', {
            ...this.params,
            q: !this.params.q ? undefined : this.params.q,
            workspace_id: this.$route.query.workspace_id,
            business_id: this.businessId,
            warehouse_id: this.$route.query.warehouse_id,
          })
          .then(({ total_row }) => this.total_row = +total_row || 0)
          .catch(() => this.isLoading = false)
      }
    },
    async fetchPrice(products) {
      this.isLoading = true
      this.products = products && products.length
        ? await Promise.all(products.map(async (product) => {
          const price = await this.getProductPrice(product.id)
          product.isOpen = false
          product.price = price.range || '-'
          product.catalogs.forEach(catalog => {
            const catalogPrice = price.list.find(p => p.catalog_id === catalog.id)
            catalog.price = catalogPrice?.price
              ? format(catalogPrice.price, 'IDR')
              : '-'
          })
          return product
        }))
        : []
      this.isLoading = false
    },
    async getProductPrice(product_id) {
      return await getPriceRange({
        business_id: this.businessId,
        product_id,
      })
        .then(({ data: { data } }) => {
          let obj = {
            list: [],
            range: null,
          }

          if (data?.length) {
            let tmpPrices = [Infinity, -Infinity]
            data.forEach(({ catalog_id, price: catalogPrices }) => {
              obj.list.push({
                catalog_id,
                price: catalogPrices[0].value,
                uom: catalogPrices[0].uom,
                currency: catalogPrices[0].currency,
              })
              catalogPrices.forEach(price => {
                tmpPrices[0] = Math.min(tmpPrices[0], price.value)
                tmpPrices[1] = Math.max(tmpPrices[1], price.value)
              })
            })

            obj.range = tmpPrices[0] == tmpPrices[1]
              ? format(tmpPrices[0], 'IDR')
              : `${format(tmpPrices[0], 'IDR')} - ${format(tmpPrices[1], 'IDR')}`
          }
          return obj
        })
        .catch(() => {
          return {
            list: [],
            range: null,
          }
        })
    },
    getBrandName(product) {
      const brand = this.$store.getters['user/businessListObjectByKey'][product.business_id] || null
      return brand?.business_title || null
    },
    onSearch() {
      this.params.page = 1
      this.fetchProducts()
    },
    onSorting(value) {
      const [sortBy, orderBy] = value.split('.')
      this.params.sortBy = value !== 'createdAt.desc'
        ? sortBy
        : undefined
      this.params.sortDir = value !== 'createdAt.desc'
        ? orderBy
        : undefined
      this.fetchProducts()
    },
    onPageChange(pageNumber) {
      this.params.page = pageNumber
      this.fetchProducts()
    },
    onShowSizeChange(current, pageSize) {
      this.params.page = current
      this.params.limit = pageSize
      this.fetchProducts()
    },
    onOpen(props, event) {
      props.record.isOpen = !props.record.isOpen
      props.onExpand(props.record, event)
    },
    checkIsWarehouseRestrictionAllowed(product_id = undefined) {
    },
    async toPage(page, product_id = undefined) {
      this.isLoading = true
      const promiseCheck = new Promise((resolve, reject) => {
        if (this.$store.state.user.restriction_base === 'Warehouse' && page === 'edit') {
          let findRestrictionList = this.$store.state.user.user_workspaces.find((obj) => obj.workspace_id === this.$route.query.workspace_id)
          if (findRestrictionList?.restriction_list?.length > 0) {
            let bodyValue = {
              business_id: this.businessId,
              product_id: product_id,
            }
            getInventoryListV2(bodyValue)
              .then(({ data }) => {
                this.isLoading = false
                data.forEach((obj) => {
                  if (findRestrictionList.restriction_list.includes(obj.warehouse_id)) {
                    resolve(true)
                  }
                })
                resolve(false)
              })
              .catch(err => {
                console.error(err)
                resolve(false)
              })
          }
        } else {
          resolve(true)
        }
      })
      const isAllowed = await promiseCheck
      if (isAllowed) {
        this.$router.push({
          name: `product.${page}`,
          query: {
            workspace_id: this.$route.query.workspace_id,
            business_id: this.$route.query.business_id,
            warehouse_id: this.$route.query.warehouse_id,
            id: product_id,
            redirect_url: page === 'create'
              ? this.$route.fullPath
              : undefined,
          },
        })
      } else {
        this.$notification.error({
          message: 'Akses ditolak',
          description: `Maaf, anda tidak memiliki akses untuk ${page} produk ini.`,
        })
      }
    },
    normalizeObjectKeyToLabel(text) {
      const result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
    async exportDataTable() {
      if (this.businessId) {
        const payload = {
          workspace_id: this.$route.query.workspace_id,
          business_id: this.businessId,
          page: 1,
          limit: this.total_row,
        }
        this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
          showModalExportSales: true,
          loadingExport: true,
        })
        await getProductMasterList({
          business_id: this.businessId,
          params: payload,
        })
        .then(async ({ data: { data: response } }) => {
          const flattenData = []
          let numbering = 0
          if (!response.length > 0) return
          await Promise.all(response.map(async (obj, idx) => {
            const price = await this.getProductPrice(obj.id)
            let flattenItem = {
              productId: obj.id,
              catalogId: '',
              noSku: '',
              noSkuPabrik: '',
              kategori: obj?.category?.name || '',
              namaProduk: '',
              'fotoProduk 1': obj?.showimg_url || '',
              'fotoProduk 2': '',
              'fotoProduk 3': '',
              'fotoProduk 4': '',
              'fotoProduk 5': '',
              opsiVarian: '',
              tipeVarian: '',
              fotoVarian: '',
              mataUang: '',
              harga: '',
              uom: '',
            }
            if (obj.catalogs.length > 0) {
              obj.catalogs.forEach(catalog => {
                numbering += 1
                let currentType = ''
                let currentOption = ''
                let currentImageUrl = ''
                if (catalog.options) {
                  Object.entries(catalog.options).forEach(([key, value]) => {
                    currentType = value
                    currentOption = key
                  })
                }
                if (catalog.image_url?.length > 0) {
                  currentImageUrl = catalog.image_url.at(0)?.imagePath
                }
                const catalogPrice = price?.list?.find(p => p.catalog_id === catalog.id)
                const newFlattenItem = {
                  catalogId: catalog.id,
                  namaProduk: catalog?.title,
                  opsiVarian: currentOption,
                  tipeVarian: currentType,
                  fotoVarian: currentImageUrl || '',
                  noSku: catalog?.sku,
                  noSkuPabrik: catalog?.factory_sku || '',
                  mataUang: catalogPrice?.currency || '',
                  harga: catalogPrice?.price || 0,
                  uom: catalogPrice?.uom || '',
                }
                flattenData.push({
                  no: numbering,
                  ...flattenItem,
                  ...newFlattenItem,
                })
              })
            } else {
              numbering += 1
              flattenData.push({
                no: numbering,
                ...flattenItem,
              })
            }
          }))
          const rowData = []
          const sampleData = flattenData.at(0)
          Object.keys(sampleData).forEach((obj) => {
            if (obj !== 'index') {
              rowData.push(this.normalizeObjectKeyToLabel(obj))
            }
          })
          const worksheet = XLSX.utils.json_to_sheet(flattenData);
          const workbook = XLSX.utils.book_new()
          XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: "A1" });
          XLSX.utils.book_append_sheet(workbook, worksheet, 'data')
          XLSX.writeFile(workbook, 'data_product.xlsx')

          this.$notification.success({
            message: 'Proses export berhasil',
          })
        })
        .catch(() => this.isLoading = false)
        .finally(() => {
          this.$store.commit('exportData/SET_STATE_EXPORT_DATA', {
            exportFileNotification: false,
            showModalExportSales: false,
            loadingExport: false,
          })
        })
      }
    },
  },
}
</script>

<style lang="scss">
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}

.master-list div.ant-table-body {
  min-height: v-bind(tHeight);
}

.catalog-list {
  border: 1px solid #e4e9f0;

  tr>th {
    padding: 10px;
    font-weight: 500;
    background-color: #f8f9fa;
  }

  tr>td {
    padding: 5px 10px;
    background-color: #fff;
  }

  tr>th,
  tr>td {
    border-bottom: 1px solid #e4e9f0;
  }
}

.master-brand {
  font-size: 10px;
  font-weight: bold;
  color: #888
}

.master-title {
  font-weight: 500;
  color: #ff0a54;
}

.master-sku {
  font-size: 12px;

  span {
    font-weight: 600;
    color: #2196F3;
    cursor: pointer
  }
}

.master-brand,
.master-title,
.master-sku {
  margin: 0;
}
</style>